import { useEffect } from "react";

const RedirectPage = () => {
  useEffect(() => {
    window.location.href = "https://turnerbund.de/lacrosse/";
  }, []);

  return null; // or you can render a loading message or component
};

export default RedirectPage;
